export const USER_ROLES = {
  SUBJECT_ADMIN: 'ADMINISTRATOR_SUBJEKTU',
  SUBJECT_USER: 'UZIVATEL_SUBJEKTU',
  ADMIN: 'ADMINISTRATOR_VVZ',
}

export const getRoleName = (roles?: string[]) => {
  if (roles?.includes(USER_ROLES.ADMIN)) {
    return 'Administrátor vvz'
  }
  if (roles?.includes(USER_ROLES.SUBJECT_ADMIN)) {
    return 'Administrátor subjektu'
  }
  if (roles?.includes(USER_ROLES.SUBJECT_USER)) {
    return 'Uživatel subjektu'
  }
  return ''
}
