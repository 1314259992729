import React from 'react'

import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom'
import KeycloakAdminProvider from '../../keycloak/KeycloakAdminProvider'
import KeycloakUserProvider from '../../keycloak/KeycloakUserProvider'
import AdminSeznamFormularu from '../../pages/AdminSeznamFormularu/AdminSeznamFormularuPage'
import AktualityDetailPage from '../../pages/AktualityDetailPage/AktualityDetailPage'
import AktualityPage from '../../pages/AktualityPage/AktualityPage'
import FormularDetailPage from '../../pages/FormularDetailPage/FormularDetailPage'
import FormulareMojePage from '../../pages/FormulareMojePage/FormulareMojePage'
import FormularePodatPage from '../../pages/FormularePodatPage/FormularePodatPage'
import FormulareProfiluPage from '../../pages/FormulareProfiluPage/FormulareProfiluPage'
import FormulareZakazkyPage from '../../pages/FormulareZakazkyPage/FormulareZakazkyPage'
import FormularTxtPage from '../../pages/FormularTxtPage/FormularTxtPage'
import FormularVyhledatPage from '../../pages/FormularVyhledatPage/FormularVyhledatPage'
import HomePage from '../../pages/HomePage/HomePage'
import InformaceDetailPage from '../../pages/InformaceDetailPage/InformaceDetailPage'
import NapovedaDetailPage from '../../pages/NapovedaDetailPage/NapovedaDetailPage'
import NapovedaPage from '../../pages/NapovedaPage/NapovedaPage'
import NotFoundPage from '../../pages/NotFoundPage/NotFoundPage'
import NoveVitezovePage from '../../pages/NoveVitezovePage/NoveVitezovePage'
import NoveVsechnaPage from '../../pages/NoveVsechnaPage/NoveVsechnaPage'
import NoveZakazkyPage from '../../pages/NoveZakazkyPage/NoveZakazkyPage'
import NoveZruseniPage from '../../pages/NoveZruseniPage/NoveZruseniPage'
import ProfilVyhledatPage from '../../pages/ProfilVyhledatPage/ProfilVyhledatPage'
import StornovanePage from '../../pages/StornovanePage/StornovanePage'
import ModalManager from '../Modal/ModalManager'
import AutoScroll from './AutoScroll'
import BaseLoginScreen from './BaseLoginScreen'
import { ErrorRouteBoundary } from './ErrorRouteBoundary'
import Layout from './Layout'
import { LINKS } from './LINKS'
import MaintenanceMode from './MaintenanceMode'
import ProtectedRoute from './ProtectedRoute'

// const RedirectTo404 = () => {
//   window.location.href = '/404-not-found?source=' + window.location.pathname
//   return null
// }

const router = createBrowserRouter([
  {
    element: (
      <>
        <ModalManager />
        <AutoScroll />
        <Outlet />
      </>
    ),
    errorElement: <ErrorRouteBoundary />,
    children: [
      {
        path: LINKS.admin,
        element: (
          <KeycloakAdminProvider>
            <Layout isAdmin />
          </KeycloakAdminProvider>
        ),
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute>
                <AdminSeznamFormularu />
              </ProtectedRoute>
            ),
          },
          {
            path: LINKS.admin + LINKS.formulareZakazky + '/:id',
            element: (
              <ProtectedRoute>
                <FormulareZakazkyPage />
              </ProtectedRoute>
            ),
          },
          {
            path: LINKS.admin + LINKS.formulareProfilu + '/:id',
            element: (
              <ProtectedRoute>
                <FormulareProfiluPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ':id',
            element: (
              <ProtectedRoute>
                <FormularDetailPage />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        element: (
          <KeycloakUserProvider>
            <Layout />
          </KeycloakUserProvider>
        ),
        children: [
          {
            path: LINKS.home,
            children: [
              {
                index: true,
                element: <HomePage />,
              },
              {
                path: LINKS.aktuality,
                children: [
                  {
                    index: true,
                    element: <AktualityPage />,
                  },
                  {
                    path: ':slug',
                    element: <AktualityDetailPage />,
                  },
                ],
              },
              {
                path: LINKS.napoveda,
                children: [
                  {
                    index: true,
                    element: <NapovedaPage />,
                  },
                  {
                    path: ':slug',
                    element: <NapovedaDetailPage />,
                  },
                ],
              },
              {
                path: LINKS.informace,
                children: [
                  {
                    path: ':slug',
                    element: <InformaceDetailPage />,
                  },
                ],
              },
              {
                path: LINKS.formulareZakazky + '/:id',
                element: <FormulareZakazkyPage />,
              },
              {
                path: LINKS.formulareProfilu + '/:id',
                element: <FormulareProfiluPage />,
              },

              {
                path: LINKS.noveZakazky,
                element: <NoveZakazkyPage />,
              },
              {
                path: LINKS.noveVsechna,
                element: <NoveVsechnaPage />,
              },
              {
                path: LINKS.noveZruseni,
                element: <NoveZruseniPage />,
              },
              {
                path: LINKS.noveVitezove,
                element: <NoveVitezovePage />,
              },
              {
                path: LINKS.stornovane,
                element: <StornovanePage />,
              },

              {
                path: LINKS.mojeVyhlaseni,
                element: (
                  <ProtectedRoute>
                    <Outlet />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: <FormulareMojePage />,
                  },
                ],
              },

              {
                path: LINKS.podatFormular,
                element: (
                  <ProtectedRoute>
                    <Outlet />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: <FormularePodatPage />,
                  },
                ],
              },

              {
                path: LINKS.vyhledatFormular,
                children: [
                  {
                    index: true,
                    element: <FormularVyhledatPage />,
                  },
                  {
                    path: ':id',
                    element: <FormularDetailPage />,
                  },
                ],
              },

              {
                path: LINKS.vyhledatProfil,
                element: <ProfilVyhledatPage />,
              },
              {
                path: LINKS.textFormular + '/:id',
                element: <FormularTxtPage />,
              },
            ],
          },
          {
            path: LINKS.notFound404,
            element: <NotFoundPage />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to={LINKS.notFound404} replace />,
        // element:<RedirectTo404/> // hard redirect na 404
      },
    ],
  },
])

function App() {
  return (
    <BaseLoginScreen>
      <MaintenanceMode>
        <RouterProvider router={router} />
      </MaintenanceMode>
    </BaseLoginScreen>
  )
}

export default App
