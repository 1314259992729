import React from 'react'
import { useUser } from '../../hooks/useUser'
import AlertError from '../Alert/AlertError'
import ButtonGov from '../Btns/ButtonGov'
import Loading from '../Loading/Loading'

interface iProtectedRoute {
  children: React.ReactNode
}

const ProtectedRoute = ({ children }: iProtectedRoute) => {
  const { userLoading, userIsLoged, login } = useUser()

  // const location = useLocation()

  if (userLoading) {
    return <Loading />
  }

  const clickLogin = () => {
    login()
  }

  if (!userIsLoged) {
    // return <Navigate to={LINKS.login} replace state={{ from: location }} />
    return (
      <div className='text-center  u-mt--50 u-mb-50'>
        <AlertError msg={'Stránka pouze pro přihlášené.'} />
        <div className='u-mt--30 u-mb--30 u-align--center'>
          <ButtonGov variant='primary' onClick={clickLogin}>
            Přihlásit se
          </ButtonGov>
        </div>
      </div>
    )
  }
  return <>{children}</>
}

export default ProtectedRoute
