import { createSelector } from '@reduxjs/toolkit'
import { USER_ROLES } from '../../enums/enumUserRoles'
import { RootState } from '../rootReducer'

const FULL_ORG_IDS = process.env.REACT_APP_FULL_ORG_IDS?.split(',').filter((a) => a) || []

export const selectUserData = (state: RootState) => state.user.userData

// export const selectUserInit = (state: RootState) => state.user.init
export const selectUserIsLoged = (state: RootState) => state.user.logedIn
export const selectUserLoading = (state: RootState) => state.user.loading

export const selectUserNameFull = (state: RootState) =>
  state.user.userData?.firstName + ' ' + state.user.userData?.lastName
export const selectUserName = (state: RootState) => state.user.userData?.username || ''
export const selectUserRoles = (state: RootState) => state.user.userData?.attributes?.role
export const selectUserSubjekt = (state: RootState) => state.user.userData?.attributes?.subjektId?.[0] || ''
export const selectUserHasFullAccess = createSelector(
  selectUserSubjekt,
  (userSubjektId) => !FULL_ORG_IDS.length || FULL_ORG_IDS.includes(userSubjektId),
)
export const selectUserIsSubjectUser = (state: RootState) =>
  state.user.userData?.attributes?.role?.includes(USER_ROLES.SUBJECT_USER)

export const selectUserSubjectData = (state: RootState) => state.user.userSubjectData
