import React from 'react'
import { useElementContext } from '../../context/ElementContext'
import { useElementEnum } from '../../hooks/useElementEnum'
import PartGovLabel from '../parts/PartGovLabel'
import PartGovLabelSimple from '../parts/PartGovLabelSimple'
import WrapInputGov from '../parts/WrapInputGov'
import { useControlRegister } from '../useControlRegister'

const InputRadio = () => {
  const { field, errors } = useControlRegister()
  const { uiSchema, id, disabled } = useElementContext()
  const { enumOptions } = useElementEnum()

  const radioCssAlign = uiSchema.options?.radioCssAlign ?? 'left'
  const radioCssColumns = uiSchema.options?.radioCssColumns || 1

  return (
    <WrapInputGov errors={errors} custom>
      <div id={id}>
        <PartGovLabel noLeftPadding />
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: radioCssAlign ? 30 : undefined,
            rowGap: radioCssAlign ? undefined : 10,
            justifyContent: radioCssAlign === 'right' ? 'end' : undefined,
          }}
        >
          {enumOptions?.map(({ label, value }, index) => (
            <div key={index} style={{ width: radioCssAlign ? 'auto' : 100 / radioCssColumns + '%' }}>
              <div className='gov-form-control gov-form-control--custom' style={{ marginBottom: 5 }}>
                <input
                  {...field}
                  onClick={(e) => {
                    if (field.value?.toString() === value) {
                      field.onChange('')
                    }
                  }}
                  id={id + '_' + value}
                  className='gov-form-control__radio'
                  type='radio'
                  checked={field.value?.toString() === value}
                  value={value}
                  disabled={disabled}
                  aria-disabled={disabled ? 'true' : 'false'}
                  aria-labelledby={id + '_' + value}
                />
                <PartGovLabelSimple id={id + '_' + value} label={label} withIndicator />
              </div>
            </div>
          ))}
        </div>
      </div>
    </WrapInputGov>
  )
}

export default InputRadio
