import React from 'react'
import { Link } from 'react-router-dom'
import { SLUG_FORM } from '../../api/EformsApi'
import AlertError from '../../components/Alert/AlertError'
import AlertInfo from '../../components/Alert/AlertInfo'
import { LINKS } from '../../components/App/LINKS'
import Loading from '../../components/Loading/Loading'
import PaginatorPerPage from '../../components/PaginatorPerPage/PaginatorPerPage'
import TextTrim from '../../components/TextTrim/TextTrim'
import { FilterContextProvider } from '../../context/FilterContext'
import { formTypeToCodeLabel } from '../../enums/enumFormTypeV2'
import { TABLE_TYPES } from '../../enums/enumTableTypes'
import { isFormDraft, WORKFLOW_PLACES } from '../../enums/enumWorkflowPlaces'
import { useFetchForms } from '../../hooks/useFetchForms'
import { useNavigateToFormVariableId } from '../../hooks/useNavigateToFormVariableId'
import { useUser } from '../../hooks/useUser'
import { iSubmissionDataHlavni } from '../../interfaces/iEformsSubmission'
import { dateFormatShort } from '../../utils/dateFormat'
import TableContractFormsProfileRepair from './TableContractFormsProfileRepair'

interface iTableContractForms {
  id: string
  isProfil?: boolean
}

const TableContractForms = ({ id, isProfil }: iTableContractForms) => {
  const { isAdmin } = useUser()
  const formSlug = isProfil ? SLUG_FORM.PROFIL : SLUG_FORM.HLAVNI
  const { data, loading, error, totalCount, maxItems } = useFetchForms<iSubmissionDataHlavni[]>(formSlug, {
    [isProfil ? 'data.evCisloProfiluVvz' : 'data.evCisloZakazkyVvz']: id,
    'order[data.datumUverejneniVvz]': 'DESC',
    'order[createdAt]': 'DESC',
  })

  const { navigateToFormVariableId } = useNavigateToFormVariableId(formSlug)

  if (error) {
    return <AlertError msg={error} />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div className='gov-table-cover gov-table-cover--nowrap u-mb--50'>
        <table className='gov-table gov-sortable-table gov-table--mobile-block'>
          <thead>
            <tr>
              <th>Ev. č. formuláře</th>
              <th>Platnost</th>
              <th>Navazuje na formulář</th>
              <th>Název zadavatele</th>
              <th>{isProfil ? 'Název profilu' : 'Název veřejné zakázky'}</th>
              <th>Druh</th>
              <th>Dat. uveřejnění</th>
            </tr>
          </thead>
          <tbody className='gov-table__body'>
            {data?.map((form, index) => (
              <tr key={index}>
                <td>
                  <Link to={(isAdmin ? LINKS.admin : LINKS.vyhledatFormular) + '/' + form.id}>
                    {isFormDraft(form.workflowPlaceCode) ? '-' : form.variableId}
                  </Link>
                </td>
                <td>
                  {form.workflowPlaceCode === WORKFLOW_PLACES.UVEREJNENO_VVZ && (
                    <>
                      {form.data.formularZneplatnen === true
                        ? 'ZNEPLATNĚNÝ'
                        : form.data.formularZneplatnen === false
                        ? form.data?.formularOpravuje
                          ? 'PLATNÝ OPRAVNÝ'
                          : 'PLATNÝ'
                        : ''}
                    </>
                  )}
                </td>
                <td>
                  {form.data.evCisloVvzSouvisejicihoFormulare && (
                    <Link to={''} onClick={() => navigateToFormVariableId(form.data.evCisloVvzSouvisejicihoFormulare!)}>
                      {form.data.evCisloVvzSouvisejicihoFormulare}
                    </Link>
                  )}
                </td>
                <td className='cellContractName'>
                  <TextTrim
                    text={
                      isProfil
                        ? (form.data as any).zadavatel?.nazev
                        : form.data.zadavatele?.map((z) => z.nazev).join(', ')
                    }
                    length={200}
                  />
                </td>
                <td className='cellContractName'>
                  <TextTrim text={isProfil ? (form.data as any).nazevProfilu : form.data.nazevZakazky} length={200} />
                </td>
                <td className='gov-table__cell--narrow'>{formTypeToCodeLabel(form.data.druhFormulare)}</td>
                <td className='gov-table__cell--narrow'>{dateFormatShort(form.data.datumUverejneniVvz)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {!data?.length && <AlertInfo />}
      </div>
      <PaginatorPerPage totalCount={totalCount} maxItems={maxItems} />
      {isProfil && (
        <FilterContextProvider name={TABLE_TYPES.profileFormsRepair}>
          <TableContractFormsProfileRepair id={id} />
        </FilterContextProvider>
      )}
    </>
  )
}

export default TableContractForms
