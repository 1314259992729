import Keycloak from 'keycloak-js'
import { toast } from 'react-toastify'
import iamApi from '../../api/IamApi'
import { iUserData } from '../../interfaces/iUserData'
import { AppThunk } from '../store'
import { clearUser, setUser, setUserLoading, setUserSubjectData } from './userSlice'

export const loadUserAction = (keycloak: Keycloak): AppThunk => {
  return async (dispatch) => {
    if (keycloak.authenticated && keycloak.token) {
      dispatch(setUserLoading(true))
      keycloak
        .loadUserProfile()
        .then((data) => {
          const userData = data as iUserData
          dispatch(setUser(userData))

          const subjectId = userData.attributes.subjektId?.[0]
          if (subjectId) {
            iamApi
              .getSubjektDetail(subjectId)
              .then((subjData) => {
                dispatch(setUserSubjectData(subjData))
              })
              .catch((e) => {
                toast.error('Chyba ziskání informací o organizaci')
              })
          }
        })
        .catch((e) => {
          toast.error('Chyba ziskání informací o uživatli')
        })
        .finally(() => dispatch(setUserLoading(false)))
    } else {
      dispatch(setUserLoading(false))
    }
  }
}

export const logOutAction = (keycloak: Keycloak, errMsg?: string): AppThunk => {
  return async (dispatch) => {
    dispatch(setUserLoading(true))

    dispatch(clearUser())
    // tokensClear()

    keycloak.logout({ redirectUri: window.location.origin }).then(() => {
      dispatch(setUserLoading(false))
    })

    if (errMsg) {
      toast.error(errMsg)
    } else {
      toast.success('Uživatel byl odhlášen')
    }
  }
}
