import get from 'lodash/get'
import React from 'react'
import { ArrowDownShort, ArrowUpShort } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import { Link } from 'react-scroll'
import { ENV } from '../../ENV'
import { selectFormTree, selectFormTreeReq } from '../../redux/formTree/formTreeSelectors'
import { getSiblingTreeEl, treeIndexesToPath } from '../../utils/formTreeSibling'
import { useIndexesContext } from '../context/IndexesContext'
import { useFormRenderContext } from '../context/RenderFormContext'
import { iFormTree } from '../utils/createFormTree'

const SectionHeaderNav = () => {
  const { isReq } = useFormRenderContext()
  const formTree = useSelector(isReq ? selectFormTreeReq : selectFormTree)
  const indexes = useIndexesContext().indexes

  const treePath = treeIndexesToPath(indexes)
  const formTreeEl: iFormTree | undefined = get(formTree, treePath)
  const formTreeElUp = getSiblingTreeEl(formTree, indexes, -1)
  const formTreeElDown = getSiblingTreeEl(formTree, indexes, 1)

  const showArrow = formTreeEl?.level !== undefined && formTreeEl?.level < ENV.FORM_MAX_ARROW_LEVEL

  const offsetUp = -ENV.FORM_TITLE_HEIGHT * Math.min(formTreeElUp?.level || 0, ENV.FORM_MAX_STICKY_LEVEL)
  const offsetDown = -ENV.FORM_TITLE_HEIGHT * Math.min(formTreeElDown?.level || 0, ENV.FORM_MAX_STICKY_LEVEL)

  return (
    <div className='section-header-nav'>
      {showArrow && formTreeElUp && formTreeElUp.to && (
        <Link
          title={'přejít na ' + formTreeElUp.text}
          smooth={true}
          isDynamic={true}
          to={formTreeElUp.to}
          offset={offsetUp}
        >
          <ArrowUpShort size={25} />
        </Link>
      )}
      {showArrow && formTreeElDown && formTreeElDown.to && (
        <Link
          title={'přejít na ' + formTreeElDown.text}
          smooth={true}
          isDynamic={true}
          to={formTreeElDown.to}
          offset={offsetDown}
        >
          <ArrowDownShort size={25} />
        </Link>
      )}
    </div>
  )
}

export default SectionHeaderNav
