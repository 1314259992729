import { useKeycloak } from '@react-keycloak/web'
import { useEffect } from 'react'
import { useUser } from '../hooks/useUser'
import { setActiveKeycloak } from './activeKeycloak'

interface iLoadUserWrap {
  children: React.ReactNode
}

export const LoadUserWrap = ({ children }: iLoadUserWrap) => {
  const { loadUser, initialized } = useUser()
  const { keycloak } = useKeycloak()

  useEffect(() => {
    setActiveKeycloak(keycloak)
  }, [keycloak])

  useEffect(() => {
    if (initialized) {
      loadUser()
    }
  }, [initialized])

  return <>{children}</>
}
