import axios from 'axios'
import { interceptorRequestAuthToken } from './interceptorRequestAuthToken'
import { interceptorResponseAuthToken } from './interceptorResponseAuthToken'

const API_URL = process.env.REACT_APP_IAM_API_URL

interface iUzivatelDetail {
  prihlasovaciJmeno: string
  jmeno: string
  prijmeni: string
  email: string
  telefon: string
  stav: string
  role: string
  datumVytvoreni: string
  datumNarozeni: string
  adresa: {
    obec: string
    ulice: string
    cisloDomovni: string
    psc: string
  }
  zpusobDolozeniOpravneni: string
}

export interface iSubjektDetail {
  id: string
  nazev: string
  stav: string
  datumVytvoreni: string
  datumPovoleni: string
  adresa: {
    obec: string
    ulice: string
    cisloDomovni: string
    psc: string
  }
  pravniForma: string
  ico: string
  dic: string
  idDatoveSchranky: string
  uzivatele: [
    {
      prihlasovaciJmeno: string
      jmenoAPrijmeni: string
      email: string
      role: string
      stav: string
    }[],
  ]
}

class IamApi {
  api = axios.create({
    baseURL: API_URL,
  })

  constructor() {
    interceptorRequestAuthToken(this.api)
    interceptorResponseAuthToken(this.api)
  }

  getSubjektDetail = (orgId: string) =>
    this.api
      .get<iSubjektDetail>(`/subjekt/detail/${orgId}`) //
      .then((res) => res.data)

  getUzivatelDetail = (username: string) =>
    this.api
      .get<iUzivatelDetail>(`/uzivatel/detail/${username}`) //
      .then((res) => res.data)
}

export default new IamApi()
