import React from 'react'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../hooks/useUser'
import { LINKS } from '../App/LINKS'
import Loading from '../Loading/Loading'

const AdminHeader = () => {
  const { t } = useTranslation()
  const { userLoading, userIsLoged, login, logout, usernameFull, orgName, roleName } = useUser()

  const clickLogout = () => {
    logout()
  }

  const clickLogin = () => {
    login()
  }

  if (userLoading) {
    return <Loading min inversed />
  }

  if (userIsLoged) {
    return (
      <>
        <a href={LINKS.cdzMujUcet} title={orgName} className='gov-button gov-button--inversed adminUserLink'>
          {usernameFull}
          <small>({roleName})</small>
        </a>
        <button
          onClick={clickLogout}
          title={t('menu.odhlasit', 'Odhlásit se')}
          className='gov-button gov-button--small gov-button--inversed'
        >
          <span className='gov-icon gov-icon--exit'></span>
        </button>
      </>
    )
  } else {
    return (
      <>
        <button
          onClick={clickLogin}
          className='gov-button  gov-button--primary-outlined  gov-button--inversed '
        >
          Přihlásit se jako admin
        </button>
      </>
    )
  }
}

export default AdminHeader
