import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { iSubjektDetail } from '../../api/IamApi'
import { iUserData } from '../../interfaces/iUserData'

interface IUserState {
  userData?: iUserData
  userSubjectData?: iSubjektDetail
  init: boolean
  logedIn: boolean
  loading: boolean
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: undefined,
    userSubjectData: undefined,
    // init: false,
    logedIn: false,
    loading: true,
  } as IUserState,
  reducers: {
    setUser: (state, action: PayloadAction<iUserData>) => {
      state.userData = action.payload
      state.logedIn = true
    },
    setUserSubjectData: (state, action: PayloadAction<iSubjektDetail>) => {
      state.userSubjectData = action.payload
    },
    // setUserInit: (state) => {
    //   state.init = true
    // },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    clearUser: (state) => {
      state.userData = undefined
      state.logedIn = false
    },
  },
})

const { actions, reducer } = userSlice
export const { setUser, clearUser, setUserLoading, setUserSubjectData } = actions

export default reducer
