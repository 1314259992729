import { useEffect } from 'react'
import { useController } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { validateLive } from '../../actions/validateLive'
import {
  clearFormErrorsPath,
  clearFormLiveErrorsPath,
  clearReqErrorsPath,
  clearReqLiveErrorsPath,
} from '../../redux/formErrors/formErrorsSlice'
import { clearIsValid } from '../../redux/formState/formStateSlice'
import { selectFormIsFullInit } from '../../redux/formTree/formTreeSelectors'
import { useAppDispatch } from '../../redux/store'
import { valueToBoolean } from '../../utils/valueToBoolean'
import { useActiveSectionContext } from '../context/ActiveSectionContext'
import { useElementContext } from '../context/ElementContext'
import { useFormRenderContext } from '../context/RenderFormContext'
import { DATA_TYPE } from '../interfaces/iDataSchema'
import { useElementErrors } from '../useElementErrors'
import { ELEMENT_TYPE } from './ELEMENTS'

export const useControlRegister = () => {
  const dispatch = useAppDispatch()
  const { dataSchema, path, rules, forbidenExt, uiSchema } = useElementContext()
  const { liveValidation, isReq } = useFormRenderContext()

  const isBoolean = dataSchema?.type === DATA_TYPE.BOOLEAN
  const errors = useElementErrors()

  const { clickActiveSection } = useActiveSectionContext()

  const { field } = useController({
    name: path,
    // defaultValue: defVal ?? defaultValue,
    // shouldUnregister: true,
    rules: rules,
  })

  useEffect(() => {
    if (forbidenExt && (field.value || field.value === false)) {
      field.onChange('')
    }
  }, [forbidenExt])

  const ELEMENT_TYPE_VALIDATION_CHANGE = [
    ELEMENT_TYPE.Select,
    ELEMENT_TYPE.SelectAutocomplete,
    ELEMENT_TYPE.Autocomplete,
    ELEMENT_TYPE.Checkbox,
    ELEMENT_TYPE.Radio,
  ]
  const liveValidateOnChange = ELEMENT_TYPE_VALIDATION_CHANGE.includes(uiSchema.type)
  const isFullInit = useSelector(selectFormIsFullInit)

  const livaValidate = () => {
    if (liveValidation && isFullInit) {
      clearErrors()
      dispatch(validateLive(isReq, path))
    }
  }

  const defOnChange = field.onChange
  field.onChange = (event: any) => {
    clearErrors()
    if (isBoolean) {
      const boolVal = event?.target ? valueToBoolean(event?.target?.value) : valueToBoolean(event)
      defOnChange(boolVal)
    } else {
      defOnChange(event)
    }
    dispatch(clearIsValid())
    if (liveValidateOnChange) {
      livaValidate()
    }
  }

  const clearErrors = () => {
    if (errors) {
      dispatch(isReq ? clearReqErrorsPath(path) : clearFormErrorsPath(path))
      dispatch(isReq ? clearReqLiveErrorsPath(path) : clearFormLiveErrorsPath(path))
      // dispatch(clearReqLiveErrorsSource(path))
      // dispatch(clearFormLiveErrorsSource(path))
    }
  }

  const defOnBlur = field.onBlur

  field.onBlur = () => {
    // dispatch(setActiveSection(''))
    if (!liveValidateOnChange) {
      livaValidate()
    }
    defOnBlur()
  }

  // @ts-ignore
  field.onFocus = () => {
    if (clickActiveSection) {
      clickActiveSection()
    }
  }
  return { field: field, errors: errors }
}
